/* Tailwind */
@import "tailwindcss";

@theme {
  --color-fg-light: #a3c1d3;
  --color-fg-dark: #1b2874;
  --font-sans: "TT Commons", sans-serif;
}

/** Basic styles **/
@font-face {
  font-family: "TT Commons";
  src: url(../fonts/TT-Commons-DemiBold.eot);
  src: url(../fonts/TT-Commons-DemiBold.eot#iefix) format("embedded-opentype"),
    url(../fonts/TT-Commons-DemiBold.woff?orgId=00D1t000000Gel9) format("woff"),
    url(../fonts/TT-Commons-DemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "TT Commons";
  src: url(../fonts/TT-Commons-Medium.eot);
  src: url(../fonts/TT-Commons-Medium.eot#iefix) format("embedded-opentype"),
    url(../fonts/TT-Commons-Medium.woff) format("woff"),
    url(../fonts/TT-Commons-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "TT Commons";
  src: url(../fonts/TT-Commons-Regular.eot);
  src: url(../fonts/TT-Commons-Regular.eot#iefix) format("embedded-opentype"),
    url(../fonts/TT-Commons-Regular.woff) format("woff"),
    url(../fonts/TT-Commons-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

body {
  font-family: "TT Commons", sans-serif;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

video {
  width: 100%;
  height: auto;
}

img {
  height: auto;
  margin: 0;
}

.grid-margin {
  padding: 0px 18px;
  margin: 0 auto;
  max-width: 1164px;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

/** Basic heading styles **/

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 42px;
  margin: 0;
  color: #171d1c; /* Black - 171D1C */
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  color: #171d1c; /* Black - 171D1C */
}

h3 {
  /* H3 Demibold */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  color: #171d1c; /* Black - 171D1C */
}

h4 {
  /* H4 Regular */
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  color: #171d1c; /* Black - 171D1C */
}

h5 {
  /* H5 Demibold */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: #171d1c; /* Black - 171D1C */
}

button,
input,
p,
textarea {
  /* Body Regular */
  font-family: "TT Commons", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: #424242; /* Darkest Grey - 424242 */
}

p.medium {
  font-weight: 500;
  color: #171d1c; /* Black - 171D1C */
}

a {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  margin: 0;
  color: #f87060; /* Coral - F87060 */
}

/** Button styles **/

.button {
  background: #f87060;
  border-radius: 49px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 7px 24px 7px 24px;
  margin: 0 36px 0 36px;
}

.button p {
  color: #ffffff;
}

/** Menu positioning styles **/

header {
  height: 84px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
}

#logo {
  height: 18px;
  width: 95px;
}

.menu-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 36px;
}

.logo-container {
  position: relative;
  top: 2px;
}

.menu-links-container a {
  color: #171d1c;
  display: inline-block;
  margin-right: 32px;
}

.menu-links-container a:last-child {
  margin-right: 0;
}

/** Hero section **/
.sticky-header-margin {
  margin-top: 84px;
}

#hero {
  margin: auto;
  max-width: 704px;
  padding-top: 180px;
  width: 100%;
}

#hero h1 {
  text-align: center;
  font-weight: 600;
  font-size: 72px;
  line-height: 78px;
  margin-bottom: 18px;
}

#hero h4 {
  text-align: center;
  color: #757575;
  font-weight: 400;
}

#hero-gif {
  max-width: 804px;
  width: 100%;
  z-index: 0;
}

#browser-header {
  z-index: 1;
  position: relative;
  margin-bottom: -10px;
  max-width: 804px;
  width: 100%;
}

#hero-media-container {
  margin: 144px auto 0;
  max-width: 804px;
  width: 100%;
}

/** clients section **/
.clients-container {
  background: #a3c1d3;
}

.clients-container .heading-container {
  padding-top: 180px;
}

/** Features section */
.first {
  padding-top: 258px;
  margin-top: -78px;
  z-index: -1;
}

/** General feature styles **/
.features-container {
  background: #1b2874;
}

.feature {
  color: white;
  padding-bottom: 60px;
}

.feature-text-container-item {
  color: white;
}

h4.feature-text-container-item {
  font-weight: 500;
  margin-bottom: 12px;
}

p.feature-text-container-item {
  opacity: 0.6;
}

.feature-text-tabs-text-container {
  margin-bottom: 36px;
}

.feature-text-tab {
  left: -999em;
  position: absolute;
}

.feature-text-tab.show {
  left: 0;
  position: relative;
}

.feature-text-tab p.feature-text-container-item {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.feature-text-tab.show p.feature-text-container-item {
  opacity: 0.6;
}

.feature-text-tab.hiding p.feature-text-container-item {
  opacity: 0;
}

.feature-text-tab-buttons {
  margin: 0 -6px;
}

.feature-text-tab-button {
  background: rgba(114, 122, 168, 0.3);
  border-radius: 18px;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 36px;
  line-height: 38px;
  margin: 0 6px;
  padding: 0 24px;
}

.feature-text-tab-button.active {
  background: #f87060;
}

.feature-text-tab-button:hover {
  background: #db594a;
}

.feature-text-container,
.feature-image-container {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
}

/** Big feature **/

.full-width-feature {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  padding-bottom: 210px;
}

.full-width-feature .feature-text-container h2 {
  font-size: 42px;
  line-height: 1.24;
  margin-bottom: 16px;
}

.full-width-feature .feature-image-container {
  display: block;
}

.full-width-feature .feature-image-container img {
  width: 100%;
}

.full-width-feature .feature-text-container-item {
  text-align: center;
}

.full-width-feature .feature-text-container,
.full-width-feature .feature-image-container {
  justify-content: center;
  max-width: 684px;
}

.full-width-feature .feature-text-container {
  margin-bottom: 90px;
}

.full-width-feature .feature-image-container {
  max-width: 840px;
}

/** small feature **/
.small-feature {
  display: flex;
}

.small-feature .feature-image-container,
.small-feature .feature-text-container {
  width: 50%;
}

.small-feature .feature-image-container {
  justify-content: center;
}

.small-feature .feature-text-container {
  justify-content: flex-start;
  padding: 0 42px;
  text-align: left;
}

.small-feature .feature-image-container img {
  height: 270px;
  width: auto;
}

/* left or right image */
.small-feature .feature-image-container-left {
  order: 1;
}

.small-feature .feature-text-container-right {
  order: 2;
}

.small-feature p.feature-text-container-item {
  margin-bottom: 36px;
}

/* specific item rules */
img#tell-story {
  width: 454px;
  height: 366px;
  padding: 42px;
}

img#share-knowledge {
  width: 490px;
  height: 208px;
  margin-left: -72px;
  padding: 121px 0;
}

/* numbers section */
.numbers-container {
  position: relative;
  margin: 180px auto 104px auto;
}

.numbers-container h2 {
  color: #171d1c;
  margin-bottom: 14px;
}
.numbers-container p {
  color: #757575;
  text-align: center;
  width: auto;
}
.counters-heading-container {
  margin-bottom: 90px;
}

.numbers-container h4 {
  color: #171d1c;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.numbers-container .counters-heading-container {
  width: 684px;
}

#counters-container {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;
}

.counter-item {
  width: 204px;
}

.counter-item p {
  width: 180px;
  margin: 0 auto;
}

#counters {
  margin: 0 auto 180px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sdgs img {
  margin: 72px auto 0 auto;
  width: 100%;
}

p.big-number {
  font-weight: 500;
  font-size: 100px;
  line-height: 90px;
  color: #171d1c;
  width: auto;
}

.sun {
  position: absolute;
  bottom: 0;
  width: 66px;
  height: 33px; /* as the half of the width */
  background-color: #f87060;
  border-top-left-radius: 66px; /* 100px of height + 10px of border */
  border-top-right-radius: 66px; /* 100px of height + 10px of border */
  border: none;
  border-bottom: 0;
  display: none;
}

.sun-left {
  left: 5%;
  bottom: 88px;
}

.sun-right {
  right: 5%;
}

/** SDG graph styles **/

/** Testimonals section **/
.testimonials-container {
  background: #a3c1d3;
  padding-bottom: 40px;
  overflow: hidden;
}

.testimonials-container .heading-container {
  margin-bottom: 54px;
}

.testimonials-container .heading-container h2 {
  margin-bottom: 0;
}

.testimonial-item {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px 36px;
}

.testimonial-item-person {
  display: inline-block;
  width: 100%;
  margin: 24px 0px 0 0;
}

.testimonial-item-person img {
  margin: 0 18px 0 0;
  float: left;
  height: 60px;
  width: 60px;
}

p.testimonial-item-name {
  color: #171d1c;
  font-weight: 500;
  margin-top: 6px;
}

p.testimonial-item-title {
  font-size: 16px;
  color: #757575;
}

/** Swiper **/
.swiper-and-buttons {
  padding: 0 60px;
  position: relative;
}

.swiper-container {
  margin: 0 auto;
  /* max-width: 1236px; */
}

.swiper-slide {
  box-sizing: border-box;
  margin-right: 0;
  padding-left: 18px;
  padding-right: 18px;
  width: 40%;
}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 15px;
}

.swiper-navigation-wrapper {
  padding: 70px 0 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}

.swiper-pagination-bullet {
  background: #e3ecf2;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #171d1c;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #171d1c;
  opacity: 1;
}

.swiper-next img,
.swiper-prev img {
  border-radius: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  display: block;
  width: 42px;
}

.swiper-prev img {
  transform: rotate(180deg);
}

/* Newsletter form */
.newsletter-form {
  margin: 0 0 36px 0;
}

.newsletter-form-container p {
  color: #757575; /** grey **/
}

.newsletter-form-container__privacy-policy {
  color: #171d1c !important;
  font-size: 16px;
}

.newsletter-form-container__privacy-policy a {
  font-size: 16px;
}

.newsletter-form__inputs,
.newsletter-form__success-message {
  display: none;
}

.newsletter-form__inputs.show,
.newsletter-form__success-message.show {
  display: block;
}

.newsletter-form__radio {
  left: -999em;
  position: absolute;
}

.newsletter-form__radio-button-list {
  margin-bottom: 40px;
  padding-top: 8px;
  text-align: center;
}

.newsletter-form__radio-label {
  color: #171d1c;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  margin: 0 18px;
  padding-left: 26px;
  position: relative;
  white-space: nowrap;
}

.newsletter-form__radio-label::before {
  border: 1px solid #bdbdbd;
  border-radius: 100%;
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: -1px;
  vertical-align: middle;
  width: 16px;
}

.newsletter-form__radio-label::after {
  border-radius: 100%;
  content: "";
  display: block;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 1px;
  vertical-align: middle;
  width: 12px;
}

.newsletter-form__radio:checked + .newsletter-form__radio-label::before {
  border-color: #f87060;
}

.newsletter-form__radio:checked + .newsletter-form__radio-label::after {
  background-color: #f87060;
}

.newsletter-form__email-button {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 6px;
  max-width: 500px;
  width: 100%;
}

.newsletter-form__inputs-button {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.newsletter-form__text-input {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  box-sizing: border-box;
  color: #171d1c;
  font-size: 18px;
  font-weight: 500;
  height: 48px;
  margin-bottom: 12px;
  padding: 2px 18px 0;
  resize: none;
  text-align: left;
  width: 100%;
}

.newsletter-form__input-count {
  position: relative;
  width: 100%;
}

.newsletter-form__count {
  bottom: 0;
  color: #757575;
}

.newsletter-form__textarea {
  height: 96px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.newsletter-form__email-button .newsletter-form__text-input {
  margin-bottom: 0;
  margin-right: 12px;
  max-width: 326px;
}

.newsletter-form__inputs-button .newsletter-form__button {
  align-self: flex-end;
}

.newsletter-form__button:hover {
  background: #db594a;
}

.newsletter-form__button:active {
  background: #cb4d3f;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.newsletter-form__button {
  background: #f87060;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 42px;
  letter-spacing: 0.14em;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  width: 160px;
}

.newsletter-form__error-message {
  color: #e70000 !important;
  font-size: 16px;
  margin: 0 auto;
  max-width: 500px;
  text-align: left;
  width: 100%;
}

.newsletter-form__error-message:empty {
  margin-bottom: 0;
}

.newsletter-form__success-message {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.newsletter-form__success-message--sign-up {
  background: #fef4f2;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 516px;
  padding: 36px 36px 24px;
}

.newsletter-form__success-message-title {
  color: #171d1c;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 6px;
  text-align: center;
}

.newsletter-form__success-message-text {
  font-size: 16px;
}

/** Sign up **/
.sign-up-container {
  text-align: center;
  margin: 180px auto;
}

.sign-up-container .heading-container {
  margin: 0 auto 36px;
  max-width: 500px;
  width: 100%;
}

.sign-up-container .heading-container h1 {
  margin-bottom: 16px;
}

.sign-up-container .heading-container p {
  color: #757575; /** grey **/
}

/** Modal **/
.modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: -999em;
  opacity: 0;
  position: absolute;
  transition: opacity 500ms ease-out;
  top: 0;
  width: 100%;
  z-index: 1000;
}

body.show-modal .modal {
  left: 0;
  opacity: 1;
  position: fixed;
}

.modal__background {
  background: #171d1c;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal__wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  padding: 50px;
  position: relative;
  z-index: 2;
}

.modal__content {
  background: #ffffff;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 100px 60px 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.modal__close {
  position: absolute;
  right: 24px;
  top: 24px;
}

/** Early accesss **/
.early-access-container {
  margin: 0 auto;
  max-width: 516px;
  text-align: center;
}

.early-access-container .heading-container {
  margin-bottom: 46px;
}

.early-access-container .heading-container h1 {
  margin-bottom: 16px;
}

.early-access-container .heading-container p {
  color: #757575; /** grey **/
}

.early-access-container .newsletter-form {
  padding-bottom: 50px;
}

/** The people behind foundgood supporters section **/
.supporters {
  background: #1b2874;
}

.supporters .heading-container {
  max-width: 444px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
}

.heading-container p,
h2 {
  color: white;
  text-align: center;
}

.heading-container h2 {
  margin-bottom: 12px;
}

.logos-container {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  width: 100%;
  flex-wrap: nowrap;
  padding-bottom: 40px;
}

.logos-container__item {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding: 0 18px;
}

.logos-container img {
  height: auto;
  max-height: 40px;
  max-width: 100%;
}

/** footer section **/
.footer-container {
  display: flex;
  margin: 48px auto;
}

.footer-container h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* Dark Blue - 1B2874 */
  color: #1b2874;
}

.footer-container p {
  font-size: 16px;
  line-height: 24px;
  /* Dark Grey - 757575 */
  color: #757575;
  width: 100%;
}

.footer-container a {
  font-size: 16px;
  line-height: 24px;
}

.footer-section {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-section img {
  margin-bottom: 6px;
}

.footer-cvr {
  margin-bottom: 36px;
}

.footer-section-contact {
  align-items: left;
  display: inline-flex;
  flex-direction: column;
  margin-left: auto;
}

.footer-section-contact h5,
.footer-section-contact p {
  white-space: nowrap;
}

.footer-section-spacer {
  margin-bottom: 18px;
}

#first {
  width: 50%;
}

#second {
  display: flex;
  width: 50%;
}

/** Reveal **/
[data-reveal] {
  visibility: hidden;
}

/** Rules for tablet **/
@media only screen and (max-width: 960px) {
  /** Menu positioning styles **/
  header {
    height: 72px;
  }

  /** general **/
  .sun {
    display: none;
  }
  /** Hero section **/
  .sticky-header-margin {
    margin-top: 72px;
  }

  /* numbers section */
  #counters-container {
    flex-direction: column;
  }

  .counter-item {
    margin-bottom: 60px;
  }

  .numbers-container {
    margin: 180px 16px 104px 16px;
  }

  .numbers-container .counters-heading-container {
    width: auto;
  }

  #sdgs-container {
    width: auto !important;
  }

  #counters {
    margin: 0 auto 120px auto;
  }

  /* features */
  .full-width-feature {
    padding-bottom: 180px;
  }

  .full-width-feature .feature-text-container,
  .full-width-feature .feature-image-container {
    max-width: 704px;
  }

  .small-feature {
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 704px;
  }

  .small-feature .feature-text-container,
  .small-feature .feature-image-container {
    width: 100%;
  }

  .small-feature .feature-text-container {
    order: 1;
  }

  .small-feature .feature-image-container {
    order: 2;
  }

  .small-feature .feature-text-container {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .feature-text-tab-button {
    margin: 4px 4px;
  }

  .small-feature .feature-image-container img {
    max-width: 100%;
  }

  /* specific item rules */
  img#report-image {
    padding: 90px 0;
  }

  img#share-knowledge {
    margin-left: 0;
  }

  /* clients */
  .clients-container .heading-container {
    padding-top: 120px;
  }

  /* Swiper */
  .swiper-pagination-bullets {
    padding-bottom: 120px;
  }

  /** Newsletter form **/
  .newsletter-form__radio-label {
    margin: 0 6px 10px;
  }

  /** Sign up **/
  .sign-up-container {
    margin: 120px auto;
  }

  /** supporters **/
  .logos-container {
    flex-wrap: wrap;
    padding-bottom: 120px;
    width: 100%;
  }

  .logos-container__item {
    margin-bottom: 60px;
    width: 100%;
  }

  .logos-container__item:last-child {
    margin-bottom: 0;
  }

  #leo-logo-white {
    -webkit-box-ordinal-group: 5;
    -moz-box-ordinal-group: 5;
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  #nnf-logo-white {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  #ole-kirks-logo-white {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  #kr-logo-white {
    -webkit-box-ordinal-group: 4;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  #lauritzen-logo-white {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
}

@media only screen and (max-width: 768px) {
  /** Swiper **/
  .swiper-and-buttons {
    margin-left: -18px;
    margin-right: -18px;
    padding: 0;
  }

  .swiper-slide {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding-left: 18px;
    padding-right: 0;
  }

  .swiper-prev,
  .swiper-next {
    display: none;
  }

  .swiper-pagination {
    padding-bottom: 90px;
  }

  /** Modal **/
  .modal__wrapper {
    padding-left: 18px;
    padding-right: 18px;
  }

  .modal__content {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 75px;
  }

  /* Newsletter form */
  .newsletter-form__inputs-button {
    margin-right: 0;
  }

  .newsletter-form__inputs-button .newsletter-form__button {
    margin-right: 0;
  }

  .newsletter-form__count {
    left: 0;
    margin-left: 0;
    position: relative;
    text-align: right;
    width: 100%;
  }
}

/** Rules for mobile **/
@media only screen and (max-width: 440px) {
  /** Menu positioning styles **/
  .menu-container {
    padding: 0 24px;
  }

  .menu-links-container a {
    margin-right: 8px;
  }

  .menu-links-container a:last-child {
    margin-right: 0;
  }

  .grid-margin {
    padding-left: 24px;
    padding-right: 24px;
  }

  /** Hero section **/
  #hero {
    padding-top: 60px;
  }

  #hero-media-container {
    margin: 36px auto 0;
  }

  #hero h1 {
    text-align: left;
    font-size: 54px;
    line-height: 54px;
    margin-bottom: 6px;
  }

  #hero h4 {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
  }

  /** clients section **/
  .clients-container .heading-container {
    padding-top: 90px;
  }

  /* features section */
  .first {
    padding-top: 150px;
  }

  .full-width-feature {
    padding-bottom: 90px;
  }

  .full-width-feature .feature-text-container h2 {
    font-size: 30px;
    line-height: 1.2;
  }

  .full-width-feature .feature-text-container-item {
    text-align: left;
  }

  .full-width-feature .feature-text-container {
    margin-bottom: 36px;
  }

  .small-feature .feature-text-container {
    padding: 0px 0px;
    text-align: left;
  }

  .feature-text-tab-button {
    font-size: 14px;
  }

  /* specific item rules */
  img#tell-story {
    width: 336px;
    height: 258px;
    padding: 36px;
  }

  img#report-image {
    width: 270px;
    height: 196.81px;
    padding: 36px 37px;
  }

  img#share-knowledge {
    width: 300px;
    height: 180px;
    padding: 36px 5px;
  }

  /** numbers section **/

  #counters-container {
    flex-wrap: wrap;
    align-content: space-between;
  }

  .numbers-container {
    margin: 78px 24px 90px 24px;
  }

  .numbers-container .heading-container {
    margin: 90px auto 90px auto;
  }

  .counter-item {
    width: 100%;
    height: 152px;
  }

  #counters {
    margin: 0 auto 96px auto;
  }

  #sdgs {
    margin-bottom: 120px;
  }

  /** Newsletter form  **/
  .newsletter-form-container__privacy-policy {
    text-align: center;
  }

  .newsletter-form__radio-label {
    display: block;
    margin-bottom: 30px;
  }

  .newsletter-form__email-button {
    display: block;
    margin-bottom: 12px;
    text-align: right;
  }

  .newsletter-form__email-button .newsletter-form__text-input {
    margin-bottom: 24px;
    margin-right: 0;
    max-width: none;
  }

  /** Sign up **/
  .sign-up-container {
    margin: 90px auto 60px;
    text-align: left;
  }

  .sign-up-container .heading-container {
    text-align: center;
  }

  .sign-up-container .heading-container h1 {
    font-size: 30px;
    line-height: 36px;
  }

  /** Early access **/
  .early-access-container {
    text-align: left;
  }

  .early-access-container .heading-container {
    text-align: center;
  }

  .early-access-container .heading-container h1 {
    font-size: 30px;
    line-height: 36px;
  }

  /** Testimonials **/
  .testimonials-container .heading-container {
    margin-bottom: 30px;
  }

  /** Swiper **/
  .swiper-and-buttons {
    margin-left: -24px;
    margin-right: -24px;
  }

  .swiper-pagination {
    padding: 45px 0 70px;
  }

  /* supporters */
  .logos-container {
    padding-bottom: 90px;
  }

  .supporters .heading-container {
    padding-top: 90px;
    text-align: center;
  }

  .supporters .heading-container p,
  .supporters .heading-container h2 {
    text-align: center;
  }

  /* footer */
  #first {
    margin-bottom: 36px;
    width: 100%;
  }

  .footer-container {
    flex-wrap: wrap;
    margin-bottom: 36px;
    margin-top: 60px;
  }

  .footer-section {
    margin-bottom: 18px;
  }

  .footer-cvr {
    margin-bottom: 12px;
  }

  .footer-section-contact {
    margin-left: 0;
  }
}
